<template>
  <div v-if="this.$store.getters.get_logged_in">
    <div class="field field_grp field_mb fit-cont">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__flag.png" alt="" />
        </div>
        <div class="field__title">Server</div>
      </div>
      <select
        name="account_server"
        v-model="account_server_c"
        class="tt-uppercase"
        @change="change_server($event)"
        translate="no"
      >
        <option
          v-for="(server, index) in servers"
          :key="index"
          :value="server.id"
        >
          {{ server.name }}
        </option>
      </select>
    </div>

    <div class="d-flex account_greet pb pt">
      <div class="flex-grow-1">
        Account:
        <span class="bold account_username" translate="no">{{ username }}</span>
      </div>
      <div class="account_top_up">
        <router-link :to="{ name: 'Shop' }">Top-up $</router-link>
      </div>
    </div>

    <table id="stats">
      <thead>
        <tr>
          <th>#</th>
          <th style="max-width: 50px !important">Character</th>
          <th>Level</th>
          <th>Coins</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="characters[account_server_c].length">
          <tr v-for="(character, i) in characters[account_server_c]" :key="i">
            <td class="text-center">{{ i + 1 }}</td>
            <td>
              <span
                :class="{
                  male: character.sex === 0,
                  female: character.sex === 1,
                }"
                class="bold"
                translate="no"
                >{{ character.char_name }}</span
              >
            </td>
            <td class="text-center">
              {{ character.level }}
            </td>
            <td class="text-center">
              {{ character.amount }}
            </td>
            <td
              :class="character.online ? 'ico_status_on' : 'ico_status_off'"
            ></td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <th class="text-center" colspan="5">
              <span class="notranslate">No characters found</span>
            </th>
          </tr>
        </template>
      </tbody>
    </table>

    <br />
    <div class="form__btn-box">
      <button
        class="button"
        type="button"
        @click="this.$router.push({ name: 'Logout' })"
      >
        <span class="button__content">Logout</span>
      </button>
    </div>
  </div>
</template>

<script>
import { __config } from "@/assets/js/config";

export default {
  name: "Account",
  data() {
    return {
      account_server: __config.server.default_server_id,
      account_character: null,
      account_default_character_set: false,
      account_default_server: 3,
      mounted: false,
    };
  },
  created() {
    if (
      !this.$store.getters.get_auth_token ||
      !this.$store.getters.get_logged_in ||
      !this.username
    ) {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    account_server_c: {
      get() {
        return this.account_server;
      },
      set(value) {
        this.account_server = value;
      },
    },
    account_character_c: {
      get() {
        return this.account_character;
      },
      set(value) {
        this.account_character = value;
      },
    },
    username() {
      return this.$store.getters.get_username;
    },
    servers() {
      return this.$store.getters.get_servers;
    },
    characters() {
      return this.$store.getters.get_characters;
    },
  },
  mounted() {
    if (
      this.$store.getters.get_logged_in &&
      !this.account_default_character_set
    ) {
      this.account_default_character_set = true;
      this.change_server(this.account_server_c);
    }
  },
  methods: {
    change_server(event) {
      let val = 0;
      if (event.target && event.target.value) {
        val = event.target.value;
      } else {
        val = event;
      }
      this.account_character_c = this.characters[val][0] ?? 0;
    },
  },
};
</script>

<style scoped>
table#stats th,
td {
  font-size: 1.16em;
  font-family: Consolas, Verdana, Arial, Tahoma, sans-serif;
}

.account_greet {
  font-size: 1.16em;
  font-family: Consolas, Verdana, Arial, Tahoma, sans-serif;
}

.account_username {
  color: #ff003b;
}

.d-flex {
  display: flex;
}

.account_top_up a {
  color: limegreen !important;
}

.flex-grow-1 {
  flex-grow: 1;
}
</style>
